import React from 'react'

import Image from '@/components/Image'

import './RaceImageStyles.less'

const RaceImageComponent = (
  {
    image,
    name,
    nameEn,
  }
) => (
  <Image
    className='RaceImage'
    data={image}
    name={name}
    nameEn={nameEn}
    noBorder
    noGap
    noText
    showEmpty
  />
)

export default RaceImageComponent
