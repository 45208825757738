import React from 'react'

import Race from '@/components/Race'
import RaceCatalog from '@/components/RaceCatalog'

import raceImageCollection from '@/constants/images/raceImageCollection'
import {pcRaceCollection} from '@/constants/pcRaceList'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Расы',
  description: 'Каталог всех игровых рас в Dungeons & Dragons',
  img: null,
}

const RacePageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={pcRaceCollection}
    ItemComponent={Race}
    itemImageCollection={raceImageCollection}
    ListComponent={RaceCatalog}
    useSingular={false}
    {...props}
  />
)

export default RacePageTemplate
