const {
  SOURCE_TWBTW,
} = require('./../../sourceList')
const {
  PC_RACE_FAIRY,
  PC_RACE_HARENGON,
} = require('./../../pcRaceIdList')

const Fairy_two_TWBTW_img = require('./../../../images/races/fairy-race.jpg')
const Haregon_TWBTW_img = require('./../../../images/races/haregon.webp')
const Haregon_lake_boat_TWBTW_img = require('./../../../images/races/haregon_lake_boat.webp')

module.exports = {
  [PC_RACE_FAIRY]: {
    src: Fairy_two_TWBTW_img,
    text: 'Мужчина и женщина фэйри',
    source: {
      id: SOURCE_TWBTW,
    },
  },
  [PC_RACE_HARENGON]: [
    {
      src: Haregon_TWBTW_img,
      text: 'Зайчан',
      source: {
        id: SOURCE_TWBTW,
      },
    },
    {
      src: Haregon_lake_boat_TWBTW_img,
      text: 'Зайчаны исследуют болото',
      source: {
        id: SOURCE_TWBTW,
      },
    },
  ],
}
