import React from 'react'

import Catalog from '@/components/Catalog'

import filterList from './constants/filterList'

import RaceList from './components/RaceList'

const RaceCatalogComponent = ({list}) => (
  <Catalog
    filterListOriginal={filterList}
    itemList={list}
    itemNameList={list}
    List={RaceList}
    pageTitle='Каталог рас'
  />
)

export default RaceCatalogComponent
