const {
  PC_RACE_GIFF,
} = require('./../../pcRaceIdList')

const {
  GiffImgList,
} = require('./../commonImageListCollection')

module.exports = {
  [PC_RACE_GIFF]: GiffImgList,
}
