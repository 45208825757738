const {
  PC_RACE_HADOZEE,
  PC_RACE_PLASMOID,
} = require('./../../pcRaceIdList')
const {
  SOURCE_ADND2_MCSA,
  SOURCE_DND3_5_SW,
  SOURCE_SJA,
} = require('./../../sourceList')

const Plasmoid_DeGleash_ADND2_MCSA_img = require('./../../../images/races/Plasmoid_DeGleash.webp')
const Plasmoid_DelNoric_ADND2_MCSA_img = require('./../../../images/races/Plasmoid_DelNoric.webp')
const Plasmoid_Ontalak_ADND2_MCSA_img = require('./../../../images/races/Plasmoid_Ontalak.webp')
const Hadozee_ADND2_MCSA_img = require('./../../../images/races/Hadozee_ADND2_MCSA.webp')
const Hadozee_ranger_DND3_5_SW_img = require('./../../../images/races/Hadozee_ranger_DND3_5_SW.webp')
const Hadozee_DND3_5_SW_img = require('./../../../images/races/Hadozee_DND3_5_SW.webp')
const Hadozee_boatswain_Tarto_SJA_img = require('./../../../images/races/hadozee-boatswain-tarto-SJA.png')

module.exports = {
  [PC_RACE_PLASMOID]: [
    {
      src: Plasmoid_DeGleash_ADND2_MCSA_img,
      text: 'Плазмоид-Де’Глиш',
      source: {
        id: SOURCE_ADND2_MCSA,
      },
    },
    {
      src: Plasmoid_DelNoric_ADND2_MCSA_img,
      text: 'Плазмоид-Дель’Норик',
      source: {
        id: SOURCE_ADND2_MCSA,
      },
    },
    {
      src: Plasmoid_Ontalak_ADND2_MCSA_img,
      text: 'Плазмоид-Онталак',
      source: {
        id: SOURCE_ADND2_MCSA,
      },
    },
  ],
  [PC_RACE_HADOZEE]: [
    {
      src: Hadozee_DND3_5_SW_img,
      text: 'Гадози',
      source: {
        id: SOURCE_DND3_5_SW,
      },
    },
    {
      src: Hadozee_ranger_DND3_5_SW_img,
      text: 'Гадози-следопыт',
      source: {
        id: SOURCE_DND3_5_SW,
      },
    },
    {
      src: Hadozee_boatswain_Tarto_SJA_img,
      text: 'Гадози боцман Тарто',
      source: {
        id: SOURCE_SJA,
      },
    },
    {
      src: Hadozee_ADND2_MCSA_img,
      text: 'Гадози',
      source: {
        id: SOURCE_ADND2_MCSA,
      },
    },
  ],
}
