const {
  GithyankiImgList,
  GithzeraiImgList,
} = require('./../commonImageListCollection')
const {PC_RACE_GITH} = require('./../../pcRaceIdList')

module.exports = {
  [PC_RACE_GITH]: [
    ...GithyankiImgList,
    ...GithzeraiImgList,
  ],
}
