const {
  SOURCE_AYAGWnW,
  SOURCE_DMG,
  SOURCE_ERFTLW,
  SOURCE_FTD,
  SOURCE_PHB,
  SOURCE_PotA,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_TIEFLING,
} = require('./../../pcRaceIdList')

const Dragonborn_male_1_img = require('./../../../images/races/dragonborn-1.png')
const Dragonborn_SCAG_img = require('./../../../images/races/dragonborn-scag.png')
const Dwarf_Golden_Male_SCAG_img = require('./../../../images/races/dwarf_golden_male_SCAG.webp')
const Dwarf_Golden_Male_2_SCAG_img = require('./../../../images/races/dwarf_golden_male_2_SCAG.webp')
const Gnome_female_1_img = require('./../../../images/races/gnome-1.png')
const Gnome_Rock_Male_DMG_img = require('./../../../images/races/gnome_rock_male_DMG.png')
const Gnome_male_1_img = require('./../../../images/races/gnome_male.jpg')
const Human_Female_Turami_PotA_img = require('./../../../images/races/human_female_turami.png')
const Humans_Kalishits_SCAG_img = require('./../../../images/races/humans_kalishits.png')
const Humans_Northlanders_SCAG_img = require('./../../../images/races/humans_northlanders.png')
const Half_elf_male_1_img = require('./../../../images/races/half-elf-1.png')
const Half_orc_female_1_img = require('./../../../images/races/half-orc-1.png')
const Half_orc_Male_SCAG_img = require('./../../../images/races/half-orc_scag.png')
const Half_orc_Mark_of_Finding_ERFTLW_img = require('./../../../images/races/half_orc_mark_of_finding.png')
const Halfling_female_1_img = require('./../../../images/races/halfling-1.png')
const Halfling_Male_SCAG_img = require('./../../../images/races/halfling_male_SCAG.png')
const Tiefling_female_1_img = require('./../../../images/races/tiefling-1.png')
const Tiefling_Male_SCAG_img = require('./../../../images/races/tiefling_male_SCAG.png')
const Dragonborn_Red_FTD_img = require('./../../../images/races/dragonborn-red.png')
const Elf_AYAGWW_img = require('./../../../images/races/elf-ayagww.png')
const Gnome_Female_AYAGWW_img = require('./../../../images/races/gnome-female-AYAGWW.png')
const Half_Elf_Female_AYAGWW_img = require('./../../../images/races/half-elf-female-AYAGWW.png')
const Half_Orc_Male_AYAGWW_img = require('./../../../images/races/half-orc-male-AYAGWW.png')
const Halfling_Male_Lightfoot_AYAGWW_img = require('./../../../images/races/halfling-male-AYAGWW.png')

const {
  City_watch_SCAG_data,
  Clawfoot_Raptor_WGtE_data,
  Cleric_of_Lathander_Human_Male_SCAG_data,
  Criminal_Background_PHB_data,
  Double_Bladed_Scimitar_WGtE_data,
  Dragonborn_College_of_creation_TCoE_data,
  Dragonborn_Monk_astral_self_TCoE_data,
  Dragonborn_Order_domain_TCoE_data,
  Dragonborn_Purple_dragon_knight_TCoE_data,
  Dragonborn_Sorcerer_TCoE_data,
  Drow_Arachnomancer_MTOF_data,
  Drow_College_of_eloquence_TCoE_data,
  Drow_High_elf_Bladesinger_TCoE_data,
  Drow_Mage_MM_data,
  Drow_Matron_mother_MTOF_data,
  Drow_PHB_data,
  Drow_Rogue_TCoE_data,
  Drow_Tiefling_Armorer_TCoE_data,
  Dwarf_Alchemist_TCoE_data,
  Dwarf_Druid_Wildfire_TCoE_data,
  Dwarf_Female_Cleric_Knowledge_PHB_data,
  Dwarf_Fighter_MGZN_DRGN_378_data,
  Dwarf_Paladin_Female_SCAG_data,
  Dwarf_with_Tentacle_Whip_ERFTLW_data,
  Elf_sun_female_PHB_data,
  Elf_Sun_Male_SCAG_data,
  Entertainer_Background_PHB_data,
  Erinis_gloom_stalker_MTG_CLB_data,
  Ettin_XGTE_data,
  Far_traveler_SCAG_data,
  Female_Cleric_hardness_TCoE_data,
  Female_Druid_Wild_companion_TCoE_data,
  Fighter_Tiefling_SCAG_data,
  Gnome_Battle_smith_2_TCoE_data,
  Gnome_Battle_smith_TCoE_data,
  Gnome_Circle_of_spores_TCoE_data,
  Gnome_Forest_Druid_Female_SCAG_data,
  Gnome_Ranger_Swarmkeeper_TCoE_data,
  Gnome_Rock_Wizard_Male_DMG_data,
  Guild_Artisan_Background_PHB_data,
  Half_Elf_Female_Druid_SCAG_data,
  Half_Orc_Male_Barbarian_Totem_SCAG_data,
  Halfling_Ranger_TCoE_data,
  Halfling_Rogue_Female_SCAG_data,
  Hermit_Background_PHB_data,
  High_elf_Psi_warrior_TCoE_data,
  Human_Artillerist_TCoE_data,
  Human_Cleric_Oghma_female_PHB_data,
  Human_Female_Barbarian_SCAG_data,
  Human_Female_Bard_SCAG_data,
  Human_Monk_Male_SCAG_data,
  Human_Paladin_seek_guidance_TCoE_data,
  Human_Path_of_the_beast_TCoE_data,
  Human_Peace_domain_TCoE_data,
  Human_Ranger_Fey_Wanderer_TCoE_data,
  Human_Tiefling_Sparring_TCoE_data,
  Human_Twilight_domain_TCoE_data,
  Human_Wizard_Order_of_Scribes_TCoE_data,
  Humans_Waterdhavian_SCAG_data,
  Inheritor_SCAG_data,
  Knight_of_the_order_SCAG_data,
  Orc_Axe_Beak_Attacks_Halfling_SKT_data,
  Orc_tattoo_Elf_TCoE_data,
  Orcs_looking_for_wounded_elf_VGTM_data,
  Outlander_Background_PHB_data,
  Pteranodon_Halfling_WGtE_data,
  Ranger_Drizzt_and_Guenhwyvar_SCAG_data,
  Ravnica_Street_Trio_GGTR_data,
  Samurai_Human_Male_DMG_data,
  Selesnya_GGTR_data,
  Soldier_Background_PHB_data,
  Sorcerer_Storm_Human_Male_SCAG_data,
  Tiefling_Circle_of_stars_TCoE_data,
  Tiefling_Oath_of_glory_TCoE_data,
  Tiefling_Rogue_Phantom_TCoE_data,
  Tiefling_Warlock_talisman_TCoE_data,
  Uthgardt_tribe_member_SCAG_data,
  Warlock_Archfey_Elf_Female_SCAG_data,
  Wizard_Sun_Elf_Female_SCAG_data,
  Wood_elf_Path_of_wild_magic_TCoE_data,
  Wood_elf_Way_of_mercy_TCoE_data,
  Tiefling_Burglar_FTD_data,
  Barbarian_Wulfgar_AYAGWW_data,
  Dragonborn_Flame_Tongue_AYAGWW_data,
  Dragonborn_Paladin_Redclay_AYAGWW_data,
  Fighter_AYAGWW_data,
  Fighter_Dwarf_Bruenor_AYAGWW_data,
  Halfling_Lightfoot_Female_Shandie_data,
  Tiefling_Sword_AYAGWW_data,
  Tiefling_Scimitar_AYAGWW_data,
  Ranger_Rashemi_Minsk_AYAGWW_data,
  Halfling_Stout_Monk_AYAGWW_data,
  Dynaheir_MTG_CLB_data,
  Shadowheart_MTG_CLB_data,
  Hexblade_MTG_AFR_data,
  Tiefling_wizard_MTG_AFR_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_RACE_DRAGONBORN]: [
    {
      src: Dragonborn_male_1_img,
      text: 'Драконорождённый чародей',
      source: {
        id: SOURCE_PHB,
      },
    },
    {
      src: Dragonborn_SCAG_img,
      text: 'Драконорождённый',
      source: {
        id: SOURCE_SCAG,
      },
    },
    Dragonborn_College_of_creation_TCoE_data,
    Dragonborn_Order_domain_TCoE_data,
    Dragonborn_Purple_dragon_knight_TCoE_data,
    Dragonborn_Sorcerer_TCoE_data,
    Dragonborn_Monk_astral_self_TCoE_data,
    {
      src: Dragonborn_Red_FTD_img,
      text: 'Красный драконорождённый наёмник ждёт клиентов',
      source: {
        id: SOURCE_FTD,
      },
    },
    Dragonborn_Flame_Tongue_AYAGWW_data,
    Dragonborn_Paladin_Redclay_AYAGWW_data,
  ],
  [PC_RACE_DWARF]: [
    Dwarf_Female_Cleric_Knowledge_PHB_data,
    {
      src: Dwarf_Golden_Male_SCAG_img,
      text: 'Золотой дварф',
      source: {
        id: SOURCE_SCAG,
      },
    },
    {
      src: Dwarf_Golden_Male_2_SCAG_img,
      text: 'Золотой дварф',
      source: {
        id: SOURCE_SCAG,
      },
    },
    Dwarf_Paladin_Female_SCAG_data,
    Fighter_Dwarf_Bruenor_AYAGWW_data,
    Dwarf_Alchemist_TCoE_data,
    Dwarf_Druid_Wildfire_TCoE_data,
    Dwarf_with_Tentacle_Whip_ERFTLW_data,
    Dwarf_Fighter_MGZN_DRGN_378_data,
  ],
  [PC_RACE_ELF]: [
    // GGTR Страницы 15, 99
    Elf_sun_female_PHB_data,
    Elf_Sun_Male_SCAG_data,
    Drow_Tiefling_Armorer_TCoE_data,
    Wood_elf_Path_of_wild_magic_TCoE_data,
    Drow_College_of_eloquence_TCoE_data,
    Drow_High_elf_Bladesinger_TCoE_data,
    Drow_Rogue_TCoE_data,
    High_elf_Psi_warrior_TCoE_data,
    Wood_elf_Way_of_mercy_TCoE_data,
    Orc_tattoo_Elf_TCoE_data,
    Drow_PHB_data,
    Drow_Mage_MM_data,
    Drow_Matron_mother_MTOF_data,
    Drow_Arachnomancer_MTOF_data,
    Orcs_looking_for_wounded_elf_VGTM_data,
    Inheritor_SCAG_data,
    Warlock_Archfey_Elf_Female_SCAG_data,
    Ranger_Drizzt_and_Guenhwyvar_SCAG_data,
    Wizard_Sun_Elf_Female_SCAG_data,
    Double_Bladed_Scimitar_WGtE_data,
    Hexblade_MTG_AFR_data,
    {
      src: Elf_AYAGWW_img,
      text: 'Эльф',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
  ],
  [PC_RACE_GNOME]: [
    {
      src: Gnome_female_1_img,
      text: 'Лесная гномка, следопытка',
      source: {
        id: SOURCE_PHB,
      },
    },
    {
      src: Gnome_male_1_img,
      text: 'Скальный гном',
      source: {
        id: SOURCE_SCAG,
      },
    },
    {
      src: Gnome_Rock_Male_DMG_img,
      text: 'Скальный гном с заводной жабой',
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Gnome_Female_AYAGWW_img,
      text: 'Скальная гномка',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
    Gnome_Battle_smith_TCoE_data,
    Gnome_Battle_smith_2_TCoE_data,
    Gnome_Circle_of_spores_TCoE_data,
    Gnome_Ranger_Swarmkeeper_TCoE_data,
    Gnome_Forest_Druid_Female_SCAG_data,
    Gnome_Rock_Wizard_Male_DMG_data,
  ],
  [PC_RACE_HALF_ELF]: [
    {
      src: Half_elf_male_1_img,
      text: 'Полуэльф-жрец',
      source: {
        id: SOURCE_PHB,
      },
    },
    {
      src: Half_Elf_Female_AYAGWW_img,
      text: 'Полуэльфийка',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
    Half_Elf_Female_Druid_SCAG_data,
    Shadowheart_MTG_CLB_data,
  ],
  [PC_RACE_HALF_ORC]: [
    {
      src: Half_orc_female_1_img,
      text: 'Полуорчиха с двуручным молотом',
      source: {
        id: SOURCE_PHB,
      },
    },
    Half_Orc_Male_Barbarian_Totem_SCAG_data,
    {
      src: Half_orc_Male_SCAG_img,
      text: 'Полуорк с рапирой',
      source: {
        id: SOURCE_SCAG,
      },
    },
    {
      src: Half_orc_Mark_of_Finding_ERFTLW_img,
      text: 'Полуорки с Метками поиска',
      source: {
        id: SOURCE_ERFTLW,
      },
    },
    {
      src: Half_Orc_Male_AYAGWW_img,
      text: 'Полуорк',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
  ],
  [PC_RACE_HALFLING]: [
    {
      src: Halfling_female_1_img,
      text: 'Коренастая полурослица-бард играет на лютне',
      source: {
        id: SOURCE_PHB,
      },
    },
    {
      src: Halfling_Male_SCAG_img,
      text: 'Коренастый полурослик играет на флейте',
      source: {
        id: SOURCE_SCAG,
      },
    },
    Guild_Artisan_Background_PHB_data,
    Halfling_Rogue_Female_SCAG_data,
    Halfling_Ranger_TCoE_data,
    Ettin_XGTE_data,
    Orc_Axe_Beak_Attacks_Halfling_SKT_data,
    Clawfoot_Raptor_WGtE_data,
    Pteranodon_Halfling_WGtE_data,
    Halfling_Lightfoot_Female_Shandie_data,
    Halfling_Stout_Monk_AYAGWW_data,
    Erinis_gloom_stalker_MTG_CLB_data,
    {
      src: Halfling_Male_Lightfoot_AYAGWW_img,
      text: 'Легконогий полурослик',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
  ],
  [PC_RACE_HUMAN]: [
    Human_Cleric_Oghma_female_PHB_data,
    Human_Artillerist_TCoE_data,
    Human_Path_of_the_beast_TCoE_data,
    Human_Peace_domain_TCoE_data,
    Human_Twilight_domain_TCoE_data,
    Female_Cleric_hardness_TCoE_data,
    Female_Druid_Wild_companion_TCoE_data,
    Human_Tiefling_Sparring_TCoE_data,
    Human_Paladin_seek_guidance_TCoE_data,
    Human_Ranger_Fey_Wanderer_TCoE_data,
    Ravnica_Street_Trio_GGTR_data,
    Selesnya_GGTR_data,
    Hermit_Background_PHB_data,
    Outlander_Background_PHB_data,
    Soldier_Background_PHB_data,
    Entertainer_Background_PHB_data,
    Knight_of_the_order_SCAG_data,
    Far_traveler_SCAG_data,
    Uthgardt_tribe_member_SCAG_data,
    City_watch_SCAG_data,
    Humans_Waterdhavian_SCAG_data,
    Dynaheir_MTG_CLB_data,
    {
      src: Humans_Kalishits_SCAG_img,
      text: 'Люди, калишиты',
      source: {
        id: SOURCE_SCAG,
      },
    },
    {
      src: Humans_Northlanders_SCAG_img,
      text: 'Люди, северяне',
      source: {
        id: SOURCE_SCAG,
      },
    },
    {
      src: Human_Female_Turami_PotA_img,
      text: 'Женщина, тёрами',
      source: {
        id: SOURCE_PotA,
      },
    },
    Human_Wizard_Order_of_Scribes_TCoE_data,
    Sorcerer_Storm_Human_Male_SCAG_data,
    Cleric_of_Lathander_Human_Male_SCAG_data,
    Samurai_Human_Male_DMG_data,
    Human_Monk_Male_SCAG_data,
    Human_Female_Barbarian_SCAG_data,
    Human_Female_Bard_SCAG_data,
    Barbarian_Wulfgar_AYAGWW_data,
    Fighter_AYAGWW_data,
    Ranger_Rashemi_Minsk_AYAGWW_data,
  ],
  [PC_RACE_TIEFLING]: [
    {
      src: Tiefling_female_1_img,
      text: 'Тифлингша-колдунья',
      source: {
        id: SOURCE_PHB,
      },
    },
    {
      src: Tiefling_Male_SCAG_img,
      text: 'Тифлинг',
      source: {
        id: SOURCE_SCAG,
      },
    },
    Criminal_Background_PHB_data,
    Drow_Tiefling_Armorer_TCoE_data,
    Tiefling_Oath_of_glory_TCoE_data,
    Tiefling_Circle_of_stars_TCoE_data,
    Human_Tiefling_Sparring_TCoE_data,
    Tiefling_Warlock_talisman_TCoE_data,
    Tiefling_Rogue_Phantom_TCoE_data,
    Fighter_Tiefling_SCAG_data,
    Tiefling_Burglar_FTD_data,
    Tiefling_Sword_AYAGWW_data,
    Tiefling_Scimitar_AYAGWW_data,
    Tiefling_wizard_MTG_AFR_data,
  ],
}
