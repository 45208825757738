const {
  SOURCE_ERFTLW,
  SOURCE_WGtE,
} = require('./../../sourceList')
const {
  PC_RACE_CHANGELING,
  PC_RACE_KALASHTAR,
  PC_RACE_SHIFTER,
  PC_RACE_WARFORGED,
} = require('./../../pcRaceIdList')
const {
  Armblade_Warforged_WGtE_data,
  Shifter_Artillerist_ERFTLW_data,
  Warforged_Wizard_WGtE_data,
} = require('./../commonImageCollection')

const Changeling_1_img = require('./../../../images/races/changeling_1.png')
const Changeling_WGtE_img = require('./../../../images/races/changeling.png')
const Changeling_Female_WGtE_img = require('./../../../images/races/changeling_female_WGtE.jpg')
const Changeling_near_Mirror_WGtE_img = require('./../../../images/races/changeling_near_mirror.webp')
const Kalashtar_female_1_img = require('./../../../images/races/kalashtar_1.png')
const Kalashtar_WGtE_img = require('./../../../images/races/kalashtar.webp')
const Shifter_wildhunt_1_img = require('./../../../images/races/shifter-wildhunt-1.png')
const Shifter_Adventurer_WGtE_img = require('./../../../images/races/shifter_adventurer.png')
const Shifters_WGtE_img = require('./../../../images/races/shifters2.png')
const Warforged_1_img = require('./../../../images/races/warforged-1.png')
const Warforged_WGtE_img = require('./../../../images/races/warforged-2.png')
const Warforged_Trio_WGtE_img = require('./../../../images/races/warforged-trio.png')
const Warforged_Builders_WGtE_img = require('./../../../images/races/warforged-builders.png')
const Warforged_Ready_to_Robber_Lightning_Train_WGtE_img = require('./../../../images/races/warforged-ready-to-robber-lightning-train.jpeg')

module.exports = {
  [PC_RACE_CHANGELING]: [
    {
      src: Changeling_1_img,
      text: 'Подменыш',
      source: {
        id: SOURCE_ERFTLW,
      },
    },
    {
      src: Changeling_WGtE_img,
      text: 'Подменыш',
      source: {
        id: SOURCE_WGtE,
      },
    },
    {
      src: Changeling_Female_WGtE_img,
      text: 'Подменыш',
      source: {
        id: SOURCE_WGtE,
      },
    },
    {
      src: Changeling_near_Mirror_WGtE_img,
      text: 'Подменыш меняет внешность перед зеркалом',
      source: {
        id: SOURCE_WGtE,
      },
    },
  ],
  [PC_RACE_KALASHTAR]: [
    {
      src: Kalashtar_female_1_img,
      text: 'Калаштарка',
      source: {
        id: SOURCE_ERFTLW,
      },
    },
    {
      src: Kalashtar_WGtE_img,
      text: 'Калаштар со своим духом',
      source: {
        id: SOURCE_WGtE,
      },
    },
  ],
  [PC_RACE_SHIFTER]: [
    {
      src: Shifter_wildhunt_1_img,
      text: 'Оборотень',
      source: {
        id: SOURCE_ERFTLW,
      },
    },
    Shifter_Artillerist_ERFTLW_data,
    {
      src: Shifter_Adventurer_WGtE_img,
      text: 'Оборотень достаёт древние свитки',
      source: {
        id: SOURCE_WGtE,
      },
    },
    {
      src: Shifters_WGtE_img,
      text: 'Оборотни',
      source: {
        id: SOURCE_WGtE,
      },
    },
  ],
  [PC_RACE_WARFORGED]: [
    {
      src: Warforged_WGtE_img,
      text: 'Кованый',
      source: {
        id: SOURCE_WGtE,
      },
    },
    {
      src: Warforged_1_img,
      text: 'Кованый',
      source: {
        id: SOURCE_ERFTLW,
      },
    },
    Warforged_Wizard_WGtE_data,
    Armblade_Warforged_WGtE_data,
    {
      src: Warforged_Trio_WGtE_img,
      text: 'Трио кованых',
      source: {
        id: SOURCE_WGtE,
      },
    },
    {
      src: Warforged_Builders_WGtE_img,
      text: 'Кованые работают на стройке',
      source: {
        id: SOURCE_WGtE,
      },
    },
    {
      src: Warforged_Ready_to_Robber_Lightning_Train_WGtE_img,
      text: 'Кованый Собирается грабить молниевый поезд',
      source: {
        id: SOURCE_WGtE,
      },
    },
  ],
}
