const {PC_RACE_GLITCHLING} = require('./../../pcRaceIdList')
const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DUODRONE,
  CREATURE_MONODRONE,
  CREATURE_QUADRONE,
} = require('./../../creatureIdList')

const monodrone = require('./../../../images/creatures/mm/monodrone.jpeg')
const duodrone = require('./../../../images/creatures/mm/duodrone.jpg')
const quadrone = require('./../../../images/creatures/mm/quadrone.jpg')

module.exports = {
  [PC_RACE_GLITCHLING]: [
    {
      src: monodrone,
      creatureId: CREATURE_MONODRONE,
      source: {
        id: SOURCE_MM,
      },
    },
    {
      src: duodrone,
      creatureId: CREATURE_DUODRONE,
      source: {
        id: SOURCE_MM,
      },
    },
    {
      src: quadrone,
      creatureId: CREATURE_QUADRONE,
      source: {
        id: SOURCE_MM,
      },
    },
  ],
}
