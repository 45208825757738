import React, {Component} from 'react'

import pcRaceList from '@/constants/pcRaceList'

import raceSorter from './utils/raceSorter'

import RaceCatalogComponent from './RaceCatalogComponent'

class RaceCatalogContainer extends Component {
  render() {
    const list = pcRaceList.sort(raceSorter)

    return (
      <RaceCatalogComponent list={list}/>
    )
  }
}

export default RaceCatalogContainer
