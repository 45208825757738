const {
  PC_RACE_GIFF_2021_10_08,
  PC_RACE_THRI_KREEN,
} = require('./../../pcRaceIdList')

const {
  GiffImgList,
  ThriKreenImgList,
} = require('./../commonImageListCollection')

module.exports = {
  [PC_RACE_GIFF_2021_10_08]: GiffImgList,
  [PC_RACE_THRI_KREEN]: ThriKreenImgList,
}
