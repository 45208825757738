import React from 'react'

import AbandonedAt from '@/components/AbandonedAt'
import DescriptionList from '@/components/DescriptionList'
import ImageList from '@/components/ImageList'
import Note from '@/components/Note'
import PageHeader from '@/components/PageHeader'
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs'
import SubRace from '@/components/SubRace'

import { pcRaceCollection } from '@/constants/pcRaceList'
import generateRacePageUrlById from '@/utils/generateRacePageUrlById'

import './RaceStyles.less'

const RaceComponent = (
  {
    abandonedAt,
    description,
    imageList,
    name,
    nameAlt,
    nameEn,
    nameEnAlt,
    note,
    releasedAs,
    releasedBeforeAsList,
    subRaceIdList,
    subRaceHeaderName,
  }
) => (
  <section className='Race'>
    <PageHeader
      className='Race_header'
      name={name}
      nameAlt={nameAlt}
      nameEn={nameEn}
      nameEnAlt={nameEnAlt}
    />
    <AbandonedAt
      className='Race_releaseInfo'
      preText='Эта раса была отменена'
      abandonedAt={abandonedAt}
    />
    <ReleasedAs
      className='Race_releaseInfo'
      preText='Эта раса была переиздана'
      releasedAs={releasedAs}
      collection={pcRaceCollection}
      urlGenerator={generateRacePageUrlById}
    />

    <section className='Race_descriptionWrapper'>
        <Note
          note={note}
          className='Race_note'
        />
        <DescriptionList description={description} />
    </section>

    <ReleasedBeforeAs
        preText='Эта раса ранее издавалась'
        releasedAs={releasedBeforeAsList}
        checkIsReady={false}
        collection={pcRaceCollection}
        urlGenerator={generateRacePageUrlById}
    />

    <section className='Race_imageListWrapper'>
        <ImageList imageList={imageList}/>
    </section>
    {
      subRaceIdList
        ? (
          <section className='Race_subRaceList'>
            <h2 className='Race_subRaceListHeader'>Подрасы {subRaceHeaderName}</h2>
            {
              subRaceIdList.map(
                subRaceId => (
                  <SubRace
                    id={subRaceId}
                    key={subRaceId}
                  />
                )
              )
            }
          </section>
        )
        : null
    }
  </section>
)

export default RaceComponent
