import { PC_RACE_CUSTOM } from '@/constants/pcRaceIdList'

const raceSorter = (
  {
    id: idA,
    name: {plural: {nominative: A}}
  },
  {
    id: idB,
    name: {plural: {nominative: B}}
  }
) => idA === PC_RACE_CUSTOM
  ? -1
  : idB === PC_RACE_CUSTOM
    ? 1
    : A > B
      ? 1
      : -1

export default raceSorter
