const {SOURCE_GGTR} = require("./../../sourceList");
const {
  PC_RACE_CENTAUR,
  PC_RACE_LOXODON,
  PC_RACE_MINOTAUR,
  PC_RACE_SIMIC_HYBRID,
  PC_RACE_VEDALKEN,
} = require('./../../pcRaceIdList')

const Minotaur_1_img = require('./../../../images/races/minotaur-1.png')
const Minotaur_Boros_img = require('./../../../images/races/minotaur-boros.png')
const Vedalken_female_Gateway_Sneak_img = require('./../../../images/races/vedalken-female-gateway-sneak.jpg')
const Vedalken_male_Mizzium_Meddler_img = require('./../../../images/races/Vedalken_male_Mizzium_Meddler.jpg')
const Vedalken_male_Crosstown_Courier_img = require('./../../../images/races/Vedalken_male_Crosstown_Courier.webp')
const Vedalken_Izzet_Blastseeker_img = require('./../../../images/races/Vedalken_Izzet_Blastseeker.jpg')
const Vedalken_female_Azorius_img = require('./../../../images/races/Vedalken_female_Azorius.jpg')
const Ravnica_Tavern_img = require('./../../../images/races/ravnica-tavern.jpg')
const Simic_Hybrid_male_1_img = require('./../../../images/races/simic-hybrid-1.webp')
const Simic_Hybrid_female_2_img = require('./../../../images/races/simic-hybrid-2.png')
const Simic_Hybrid_male_3_img = require('./../../../images/races/simic-hybrid-3.png')
const Simic_Hybrid_Flier_female_4_img = require('./../../../images/creatures/ggtr/hybrid_flier.png')
const Simic_Hybrid_Shocker_male_5_img = require('./../../../images/creatures/ggtr/hybrid_shocker.png')

const {
  Centaur_Male_MM_data,
  Centaur_Female_GGTR_data,
  Centaur_Male_ADND2_data,
  Centaur_Male_AYAGMnC_data,
  Loxodon_Selesnya_GGTR_data,
  Ravnica_Street_Trio_GGTR_data,
  Selesnya_GGTR_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_RACE_CENTAUR]: [
    Centaur_Male_AYAGMnC_data,
    Centaur_Male_MM_data,
    Centaur_Female_GGTR_data,
    Centaur_Male_ADND2_data,
    Selesnya_GGTR_data,
  ],
  [PC_RACE_LOXODON]: [
    Loxodon_Selesnya_GGTR_data,
    Ravnica_Street_Trio_GGTR_data,
    Selesnya_GGTR_data,
  ],
  [PC_RACE_MINOTAUR]: [
    {
      src: Minotaur_Boros_img,
      text: 'Минотавр Боросов',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Minotaur_1_img,
      text: 'Минотавр Груулов',
      source: {
        id: SOURCE_GGTR,
      },
    },
  ],
  [PC_RACE_VEDALKEN]: [
    {
      src: Vedalken_male_Crosstown_Courier_img,
      text: 'Городской посыльный',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Vedalken_Izzet_Blastseeker_img,
      text: 'Иззетский взрывоискатель',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Vedalken_male_Mizzium_Meddler_img,
      text: 'Миззиевый сумасброд Иззетов',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Vedalken_female_Gateway_Sneak_img,
      text: 'Привратная воровка',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Vedalken_female_Azorius_img,
      text: 'Вещий маг сената Азоруисов',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Ravnica_Tavern_img,
      text: 'Дискуссия в кафе',
      source: {
        id: SOURCE_GGTR,
      },
    },
  ],
  [PC_RACE_SIMIC_HYBRID]: [
    {
      src: Simic_Hybrid_male_1_img,
      text: 'Гибрид Симиков',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Simic_Hybrid_female_2_img,
      text: 'Плащеносный мистик Симиков',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Simic_Hybrid_male_3_img,
      text: 'Проект Стражник',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Simic_Hybrid_Flier_female_4_img,
      text: 'Гибрид-летунья',
      source: {
        id: SOURCE_GGTR,
      },
    },
    {
      src: Simic_Hybrid_Shocker_male_5_img,
      text: 'Гибрид-шокер',
      source: {
        id: SOURCE_GGTR,
      },
    },
    Ravnica_Street_Trio_GGTR_data,
  ],
}
