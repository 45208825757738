const {
  PC_RACE_GRUNG,
} = require('./../../pcRaceIdList')

const {
  Grungs_VGTM_data,
  Grungs_Green_OGA_list,
  Grungs_Orange_OGA_data,
  Grungs_Purple_OGA_data,
  Grungs_Red_OGA_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_RACE_GRUNG]: [
    ...Grungs_Green_OGA_list,
    Grungs_Orange_OGA_data,
    Grungs_Purple_OGA_data,
    Grungs_Red_OGA_data,
    Grungs_VGTM_data,
  ],
}
