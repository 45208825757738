const {
  PC_RACE_AASIMAR,
  PC_RACE_BUGBEAR,
  PC_RACE_FILBORG,
  PC_RACE_GENASI,
  PC_RACE_GOBLIN,
  PC_RACE_GOLIATH,
  PC_RACE_HOBGOBLIN,
  PC_RACE_KENKU,
  PC_RACE_KOBOLD,
  PC_RACE_LIZARDFOLK,
  PC_RACE_ORC,
  PC_RACE_TABAXI,
  PC_RACE_TRITON,
  PC_RACE_YUAN_TI_PUREBLOOD,
} = require('./../../pcRaceIdList')
const {
  SOURCE_AYAGWnW,
  SOURCE_EE,
  SOURCE_MM,
  SOURCE_MTG_CLB,
  SOURCE_VGTM,
  SOURCE_VRGtR,
} = require('./../../sourceList')

const Aasimar_1_img = require('./../../../images/races/aasimar-1.webp')
const Bugbear_male_1_img = require('./../../../images/races/bugbear-1.jpg')
const Filborg_male_1_img = require('./../../../images/races/filborg-1.jpg')
const Genasi_Air_Male_1_img = require('./../../../images/races/genasi-air-1.png')
const Genasi_Earth_Male_1_img = require('./../../../images/races/genasi-earth-1.jpg')
const Genasi_Fire_Female_1_img = require('./../../../images/races/genasi-fire-1.jpg')
const Genasi_Water_Male_1_img = require('./../../../images/races/genasi-water-1.jpg')
const Kobold_1_img = require('./../../../images/races/kobold-1.jpg')
const Orc_male_1_img = require('./../../../images/races/orc-1.png')
const Tabaxi_male_1_img = require('./../../../images/races/tabaxi-1.jpg')
const Triton_male_1_img = require('./../../../images/races/triton-1.webp')
const Yuan_ti_pureblood_female_1_img = require('./../../../images/races/yuan_ti_pureblood-1.jpeg')
const Orc_Hero_img = require('./../../../images/races/orc-hero.png')
const Tabaxi_Axe_AYAGWW_img = require('./../../../images/races/tabaxi-axe-ayagww.jpg')
const Cadira_orc_female_SOURCE_MTG_CLB_img = require('./../../../images/races/cadira_orc_female.jpg')

const {
  GoblinsImgList,
  LizardFolksList,
} = require('./../commonImageListCollection')

const {
  Bugbear_ADND2_data,
  Bugbear_AYAGMnC_data,
  Goblins_bugbears_AYAGWnW_data,
  Goliath_VGTM_data,
  Goliath_Warrior_and_Spellcaster_EGTW_data,
  Goliath_Warrior_IDRotF_data,
  Kobold_and_Orc_VGTM_data,
  Hobgoblin_Devastator_VGTM_data,
  Hobgoblin_Iron_Shadow_VGTM_data,
  Hobgoblin_MM_data,
  Hobgoblin_War_camp_VGTM_data,
  Kenku_MM_data,
  Kenku_VGTM_data,
  Kenku_DMG_data,
  Kobold_Dragonshield_VGTM_data,
  Kobolds_MTG_CLB_data,
  Kobold_Inventor_VGTM_data,
  Kobold_Scale_sorcerer_VGTM_data,
  Kobold_Trap_VGTM_data,
  Kobold_Tribe_VGTM_data,
  Kugluk_FTD_data,
  Orc_Axe_Beak_Attacks_Halfling_SKT_data,
  Orcs_looking_for_wounded_elf_VGTM_data,
  Orc_eye_of_Gruumsh_VGTM_data,
  Orc_Rune_knight_TCoE_data,
  Orc_s_War_wagon_VGTM_data,
  Orc_tattoo_Elf_TCoE_data,
  Orc_Way_of_the_four_elements_TCoE_data,
  Kenku_Monk_Whey_Shu_AYAGWW_data,
  Tabaxi_AYAGWW_data,
  Kenku_Rogue_AYAGWW_data,
  Kenku_Fights_Goblins_AYAGWW_data,
  Orc_feywild_caretaker_MTG_CLB_data,
  Kenku_artificer_MTG_CLB_data,
  Filborg_flute_enthralling_performance_MTG_CLB_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_RACE_AASIMAR]: {
    src: Aasimar_1_img,
    text: 'Аасимара-защитница с сияющей душой',
    source: {
      id: SOURCE_VGTM,
    },
  },
  [PC_RACE_BUGBEAR]: [
    {
      src: Bugbear_male_1_img,
      text: 'Багбир',
      source: {
        id: SOURCE_MM,
      },
    },
    Bugbear_AYAGMnC_data,
    Bugbear_ADND2_data,
    Goblins_bugbears_AYAGWnW_data,
  ],
  [PC_RACE_FILBORG]: [
    {
      src: Filborg_male_1_img,
      text: 'Фирболг',
      source: {
        id: SOURCE_VGTM,
      },
    },
    Filborg_flute_enthralling_performance_MTG_CLB_data,
  ],
  [PC_RACE_GENASI]: [
    {
      src: Genasi_Air_Male_1_img,
      text: 'Генази воздуха',
      source: {
        id: SOURCE_EE,
      },
    },
    {
      src: Genasi_Earth_Male_1_img,
      text: 'Генази земли',
      source: {
        id: SOURCE_EE,
      },
    },
    {
      src: Genasi_Fire_Female_1_img,
      text: 'Генази огня',
      source: {
        id: SOURCE_EE,
      },
    },
    {
      src: Genasi_Water_Male_1_img,
      text: 'Генази воды',
      source: {
        id: SOURCE_EE,
      },
    },
  ],
  // GGTR Страницы 115, 161, 162
  [PC_RACE_GOBLIN]: [
    ...GoblinsImgList,
    Kenku_Fights_Goblins_AYAGWW_data,
  ],
  [PC_RACE_GOLIATH]: [
    Goliath_VGTM_data,
    Goliath_Warrior_IDRotF_data,
    Goliath_Warrior_and_Spellcaster_EGTW_data,
  ],
  [PC_RACE_HOBGOBLIN]: [
    Hobgoblin_MM_data,
    Hobgoblin_Devastator_VGTM_data,
    Hobgoblin_Iron_Shadow_VGTM_data,
    Hobgoblin_War_camp_VGTM_data,
  ],
  [PC_RACE_KENKU]: [
    Kenku_Monk_Whey_Shu_AYAGWW_data,
    Kenku_MM_data,
    Kenku_VGTM_data,
    Kenku_DMG_data,
    Kenku_Rogue_AYAGWW_data,
    Kenku_Fights_Goblins_AYAGWW_data,
    Kenku_artificer_MTG_CLB_data,
  ],
  [PC_RACE_KOBOLD]: [
    {
      src: Kobold_1_img,
      text: 'Кобольд',
      source: {
        id: SOURCE_MM,
      },
    },
    Kobold_Dragonshield_VGTM_data,
    Kobold_Inventor_VGTM_data,
    Kobold_Scale_sorcerer_VGTM_data,
    Kobold_Trap_VGTM_data,
    Kobold_Tribe_VGTM_data,
    Kugluk_FTD_data,
    Kobolds_MTG_CLB_data,
    Kobold_and_Orc_VGTM_data,
  ],
  [PC_RACE_LIZARDFOLK]: LizardFolksList,
  [PC_RACE_ORC]: [
    {
      src: Orc_male_1_img,
      text: 'Орк',
      source: {
        id: SOURCE_MM,
      },
    },
    {
      src: Cadira_orc_female_SOURCE_MTG_CLB_img,
      text: 'Кадира, Созывающая Малых',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    Orc_Way_of_the_four_elements_TCoE_data,
    Orc_Rune_knight_TCoE_data,
    Orc_tattoo_Elf_TCoE_data,
    Kobold_and_Orc_VGTM_data,
    Orcs_looking_for_wounded_elf_VGTM_data,
    Orc_eye_of_Gruumsh_VGTM_data,
    Orc_s_War_wagon_VGTM_data,
    Orc_Axe_Beak_Attacks_Halfling_SKT_data,
    Orc_feywild_caretaker_MTG_CLB_data,
    {
      src: Orc_Hero_img,
      text: 'Несмотря на укус вампира, герой-орк встречает свои страхи',
      source: {
        id: SOURCE_VRGtR,
      },
    },
  ],
  [PC_RACE_TABAXI]: [
    {
      src: Tabaxi_male_1_img,
      text: 'Табакси',
      source: {
        id: SOURCE_VGTM,
      },
    },
    {
      src: Tabaxi_Axe_AYAGWW_img,
      text: 'Табакси с топором',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
    Tabaxi_AYAGWW_data,
  ],
  [PC_RACE_TRITON]: {
    src: Triton_male_1_img,
    text: 'Тритон',
    source: {
      id: SOURCE_VGTM,
    },
  },
  [PC_RACE_YUAN_TI_PUREBLOOD]:  {
    src: Yuan_ti_pureblood_female_1_img,
    text: 'Чистокровная юань-ти',
    source: {
      id: SOURCE_MM,
    },
  },
}
