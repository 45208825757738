const {PC_RACE_TORTLE} = require('./../../pcRaceIdList')
const {
  Tortle_AYAGWW_data,
  Tortle_TTP_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_RACE_TORTLE]: [
    Tortle_TTP_data,
    Tortle_AYAGWW_data,
  ],
}
