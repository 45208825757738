import React from 'react'

import upLetter from '@/utils/upLetter'

import { pcRaceCollection } from '@/constants/pcRaceList'
import { pcSubraceIdByRaceIdDict } from '@/constants/pcSubraceList'
import raceImageCollection from '@/constants/images/raceImageCollection'

import RaceComponent from './RaceComponent'

const RaceContainer = ({id}) => {
  const race = pcRaceCollection[id]

  if (race) {
    const {
      name: {
        plural: {
          nominative: name,
          genitive: subRaceHeaderName,
        },
      },
    } = race
    const imageList = raceImageCollection[id]
    const subRaceIdList = pcSubraceIdByRaceIdDict[id]

    return (
      <RaceComponent
        {...race}
        imageList={imageList}
        name={upLetter(name)}
        subRaceHeaderName={subRaceHeaderName}
        subRaceIdList={subRaceIdList}
      />
    )
  }

  return null
}

export default RaceContainer
