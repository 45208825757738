const {PC_RACE_KENDER} = require('./../../pcRaceIdList')
const {SOURCE_DND3_DLCS} = require('./../../sourceList')

const Kender_DND3_DLCS_img = require('./../../../images/races/Kender_3e.webp')

module.exports = {
  [PC_RACE_KENDER]: {
    src: Kender_DND3_DLCS_img,
    text: 'Кендеры',
    source: {
      id: SOURCE_DND3_DLCS,
    },
  },
}
