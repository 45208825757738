import React from 'react'
import { Link } from 'gatsby'

import generateRacePageUrlById from '@/utils/generateRacePageUrlById'

import './RaceLinkStyles.less'

const RaceLink = (
  {
    children,
    id,
    isReady,
  }
) => isReady
  ? (
    <Link
      className='RaceLink'
      to={generateRacePageUrlById(id)}
    >
      {children}
    </Link>
  )
  : (
    <div className='RaceLink'>
      {children}
    </div>
  )

export default RaceLink
