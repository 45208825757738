import React from 'react'

import upLetter from '@/utils/upLetter'

import DescriptionList from '@/components/DescriptionList'
import Note from '@/components/Note'

import { pcSubraceCollection } from '@/constants/pcSubraceList'

import './SubRaceStyles.less'
import PageHeader from "@/components/PageHeader";

const SubRaceContainer = ({id}) => {
  const subRace = pcSubraceCollection[id]

  if (subRace) {
    const {description, note, nameAlt, nameEnAlt, nameEn, name: {plural: name}} = subRace

    return (
      <section className='SubRace'>
        <PageHeader
          className='SubRace_header'
          name={name}
          nameAlt={nameAlt}
          nameEn={nameEn}
          nameEnAlt={nameEnAlt}
        />
        <section className='SubRace_descriptionWrapper'>
          <Note note={note}/>
          <DescriptionList
            description={description}
            name={upLetter(name)}
          />
        </section>
      </section>
    )
  }

  return null
}

export default SubRaceContainer
