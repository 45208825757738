import React from 'react'
import arrify from 'arrify'

import upLetter from '@/utils/upLetter'

import raceImageCollection from '@/constants/images/raceImageCollection'
import { pcRaceCollection } from '@/constants/pcRaceList'

import RaceImageComponent from './RaceImageComponent'

const RaceImageContainer = ({id}) => {
  if (id) {
    const race = pcRaceCollection[id]

    if (race) {
      const { name, nameEn } = race

      const image = raceImageCollection[id]
        ? arrify(raceImageCollection[id])[0]
        : null

      return (
        <RaceImageComponent
          image={image}
          name={upLetter(name)}
          nameEn={nameEn}
        />
      )
    }
  }

  return null
}

export default RaceImageContainer
