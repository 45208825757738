module.exports = {
  ...require('./raceImageCollection_AI'),
  ...require('./raceImageCollection_ADND2_MCSA'),
  ...require('./raceImageCollection_ADND2_SJCS'),
  ...require('./raceImageCollection_EE'),
  ...require('./raceImageCollection_ERFTLW'),
  ...require('./raceImageCollection_GGTR'),
  ...require('./raceImageCollection_LR'),
  ...require('./raceImageCollection_MOOT'),
  ...require('./raceImageCollection_MTOF'),
  ...require('./raceImageCollection_OGA'),
  ...require('./raceImageCollection_PHB'),
  ...require('./raceImageCollection_SAiS'),
  ...require('./raceImageCollection_SaCoC'),
  ...require('./raceImageCollection_TTP'),
  ...require('./raceImageCollection_TWBTW'),
  ...require('./raceImageCollection_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE'),
  ...require('./raceImageCollection_UA_2022_03_08_HEROES_OF_KRYNN'),
  ...require('./raceImageCollection_UA_2022_07_18_WotM'),
  ...require('./raceImageCollection_VGTM'),
  ...require('./raceImageCollection_VRGtR'),
}
