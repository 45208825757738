import React from 'react'

import RaceItem from './../RaceItem'

import './RaceListStyles.less'

const RaceList = ({itemNotGroupedList: list}) => (
  <ul className='RaceList'>
    {
      list.map(
        item => (
          <RaceItem
            key={item.id}
            {...item}
          />
        )
      )
    }
  </ul>
)

export default RaceList
