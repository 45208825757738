import React from 'react'
import classNames from 'classnames'

import upLetter from '@/utils/upLetter'

import RaceImage from './../RaceImage'
import RaceLink from './../RaceLink'

import './RaceItemStyles.less'

const RaceItem = ({id, isReady, isUA, name: {plural: {nominative: name}}}) => (
  <li
    key={id}
    className={classNames(
      'RaceItem',
      {
        'RaceItem-notReady': !isReady,
        'RaceItem-isUA': isUA,
      }
    )}
    title={
      isUA
        ? 'Раса издана только в Раскопанных Тайнах и не издавалась официально'
        : ''
    }
  >
    <RaceLink
      id={id}
      isReady={isReady}
    >
      <header className='RaceItem_header'>
        {upLetter(name)}
      </header>
      <RaceImage id={id}/>
    </RaceLink>
  </li>
)

export default RaceItem
