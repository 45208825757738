const {
  PC_RACE_LEONIN,
  PC_RACE_SATYR,
} = require('./../../pcRaceIdList')

const {
  SOURCE_MOOT,
} = require('./../../sourceList')

const {
  Satyr_MM_data,
} = require('./../commonImageCollection')

const Leonin_MOOT_img = require('./../../../images/races/leonin-1.png')

module.exports = {
  [PC_RACE_SATYR]: [
    Satyr_MM_data,
  ],
  [PC_RACE_LEONIN]: {
    src: Leonin_MOOT_img,
    text: 'Леонинец — герой прайда',
    source: {
      id: SOURCE_MOOT,
    },
  },
}
