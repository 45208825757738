const {SOURCE_VRGtR} = require('./../../sourceList')
const {
  PC_RACE_DHAMPIR,
  PC_RACE_HEXBLOOD,
  PC_RACE_REBORN,
} = require('./../../pcRaceIdList')

const { Dhampir_rogue_VRGtR_data } = require('./../commonImageCollection')

const Hexblood_Night_Hag_img = require('./../../../images/races/hexblood.png')
const Hexblood_Cauldron_img = require('./../../../images/races/hexblood-cauldron.png')
const Dhampir_Savra_Sunstar_img = require('./../../../images/races/dhampir-savra-sunstar.png')
const Reborn_img = require('./../../../images/races/reborn.png')

module.exports = {
  [PC_RACE_DHAMPIR]: [
    Dhampir_rogue_VRGtR_data,
    {
      src: Dhampir_Savra_Sunstar_img,
      text: 'Дампирша Савра Солнцезвёздная сражается со своим отцом, вампиром Яндером Солнцезвёздным',
      source: {
        id: SOURCE_VRGtR,
      },
    },
  ],
  [PC_RACE_HEXBLOOD]: [
    {
      src: Hexblood_Night_Hag_img,
      text: 'Порчекровный, созданный ночной каргой',
      source: {
        id: SOURCE_VRGtR,
      },
    },
    {
      src: Hexblood_Cauldron_img,
      text: 'Порчекровные, наполненные магией морской, зелёной и аннис карг, проводят время у котла',
      source: {
        id: SOURCE_VRGtR,
      },
    },
  ],
  [PC_RACE_REBORN]: {
    src: Reborn_img,
    text: 'Перерождённая с призрачной конечностью целится',
    source: {
      id: SOURCE_VRGtR,
    },
  },
}
