import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import settingList from '@/constants/settingList'
import { GENDER_MALE } from '@/constants/genderList'
import { pcRaceSizeIdList } from '@/constants/pcRaceList'
import { sizeCollection } from '@/constants/sizeList'
import { FILTER_TYPE_SELECT } from '@/constants/filterTypes'

import sortByText from '@/utils/sortByText'
import upLetter from '@/utils/upLetter'

export default [
  {
    label: 'Сеттинг',
    type: FILTER_TYPE_SELECT,
    propName: 'setting',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой',
      },
      ...settingList
        .map((
          {
            name: text,
            id: value,
          },
        ) => (
          {
            text,
            value,
          }
        ))
        .sort(sortByText),
    ],
  },
  {
    label: 'Размер',
    type: FILTER_TYPE_SELECT,
    propName: 'sizeType',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой',
      },
      ...pcRaceSizeIdList
        .map(
          sizeId => ({
            text: upLetter(sizeCollection[sizeId].name.singular[GENDER_MALE].nominative),
            value: sizeId,
          }),
        )
        .sort(sortByText),
    ],
  },
  {
    label: 'Переиздана?',
    title: 'Раса была переиздана в другой официальной версии',
    type: FILTER_TYPE_SELECT,
    propName: 'isReReleased',
    value: false,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
]
