const {
  PC_RACE_AARAKOCRA,
  PC_RACE_GENASI,
} = require('./../../pcRaceIdList')
const {
  AarakokraList,
} = require('./../commonImageListCollection')

const Genasi_air_male_1_img = require('./../../../images/races/genasi-air-1.png')

module.exports = {
  [PC_RACE_AARAKOCRA]: AarakokraList,
  [PC_RACE_GENASI]: Genasi_air_male_1_img,
}
