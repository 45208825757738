const {
  SOURCE_ADND2_SJCS,
  SOURCE_SJA,
} = require('./../../sourceList')
const Autognome_ADND2_SJCS_img = require('./../../../images/races/autognome.webp')
const MrBlip_SJA_img = require('./../../../images/races/autognome_mr_blip_SJA.webp')
const {PC_RACE_AUTOGNOME} = require('./../../pcRaceIdList')

module.exports = {
  [PC_RACE_AUTOGNOME]: [
    {
      src: MrBlip_SJA_img,
      text: 'Автогном Мистер Блип',
      source: {
        id: SOURCE_SJA,
      },
    },
    {
      src: Autognome_ADND2_SJCS_img,
      text: 'Автогном',
      source: {
        id: SOURCE_ADND2_SJCS,
      },
    },
  ],
}
