const {PC_RACE_OWLIN} = require('./../../pcRaceIdList')
const {SOURCE_SaCoC} = require('./../../sourceList')

const Owlin_SaCoC_img = require('./../../../images/races/owlin-SaCoC.jpg')

module.exports = {
  [PC_RACE_OWLIN]: {
    src: Owlin_SaCoC_img,
    text: 'Совин',
    source: {
      id: SOURCE_SaCoC,
    },
  },
}
